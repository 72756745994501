// extracted by mini-css-extract-plugin
export var Frame = "Superchange-module--Frame--4a6eb";
export var Rec = "Superchange-module--Rec--dbd58";
export var advance = "Superchange-module--advance--75a8a";
export var cir = "Superchange-module--cir--4eb5a";
export var dots = "Superchange-module--dots--3a0c9";
export var for1 = "Superchange-module--for1--7ce68";
export var heading = "Superchange-module--heading--ef588";
export var iconssCard = "Superchange-module--iconssCard--56111";
export var techBg = "Superchange-module--techBg--9befe";
export var techDot = "Superchange-module--techDot--295df";
export var techIcon = "Superchange-module--techIcon--e07cc";
export var techImg = "Superchange-module--techImg--d027f";
export var technologyIcon = "Superchange-module--technologyIcon--1a2a9";