// extracted by mini-css-extract-plugin
export var Group = "CreatePowerful-module--Group--da632";
export var Vector = "CreatePowerful-module--Vector--b2691";
export var card = "CreatePowerful-module--card--e4a03";
export var cardWrapper = "CreatePowerful-module--cardWrapper--bdd77";
export var choseImg = "CreatePowerful-module--choseImg--d97ec";
export var cir = "CreatePowerful-module--cir--22577";
export var description = "CreatePowerful-module--description--224d3";
export var dots1 = "CreatePowerful-module--dots1--2efc7";
export var dots2 = "CreatePowerful-module--dots2--9d843";
export var dots4 = "CreatePowerful-module--dots4--17ff7";
export var dotscard = "CreatePowerful-module--dotscard--2dec9";
export var heading = "CreatePowerful-module--heading--7690b";
export var react = "CreatePowerful-module--react--7d764";
export var subservice = "CreatePowerful-module--subservice--68cd8";
export var teamBtn = "CreatePowerful-module--teamBtn--2849f";