import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Discover.module.scss"
import lines from "../../images/cross-plateform/3lines.svg"
import arrow from "../../images/cross-plateform/arrow.svg"
import box from "../../images/cross-plateform/box.svg"
import lines2 from "../../images/cross-plateform/2lines.svg"
import doted from "../../images/cross-plateform/dotedLinne.png"

const Discover = ({ strapiData }) => {
  return (
    <div className={styles.build}>
      <div>
        <Container>
          <div className={styles.buildInner}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            ></h2>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />

            <div className={styles.cardData}>
              <img
                src={lines}
                className={styles.three}
                decoding="async"
                loading="lazy"
                alt="icon"
              />
              <img
                src={lines2}
                className={styles.two2}
                decoding="async"
                loading="lazy"
                alt="icon"
              />
              {strapiData?.cards?.map((e, i) => (
                <div className={styles.card} key={i}>
                  <div className={styles.bgImg}>
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                  <h3>{e?.title}</h3>
                  <p>{e?.subTitle}</p>
                  <img
                    src={arrow}
                    decoding="async"
                    loading="lazy"
                    alt="arrow"
                    className={styles.arrow}
                  />
                  <img
                    src={box}
                    className={styles.dot}
                    decoding="async"
                    loading="lazy"
                    alt="icon"
                  />
                </div>
              ))}
              <img
                src={lines2}
                className={styles.two}
                decoding="async"
                loading="lazy"
                alt="icon"
              />
            </div>
          </div>
        </Container>
      </div>
      <img
        src={doted}
        className={styles.doted}
        decoding="async"
        loading="lazy"
        alt="icon"
      />
    </div>
  )
}
export default Discover
