// extracted by mini-css-extract-plugin
export var arrow = "Discover-module--arrow--f3958";
export var bannerBtn2 = "Discover-module--bannerBtn2--5763f";
export var bgImg = "Discover-module--bgImg--6415a";
export var btn_white1_border = "Discover-module--btn_white1_border--2a00b";
export var build = "Discover-module--build--0a984";
export var buildInner = "Discover-module--buildInner--bf763";
export var card = "Discover-module--card--4a0aa";
export var cardData = "Discover-module--cardData--c06c9";
export var description = "Discover-module--description--75d31";
export var dot = "Discover-module--dot--a843d";
export var doted = "Discover-module--doted--51495";
export var heading = "Discover-module--heading--f1a9c";
export var three = "Discover-module--three--425df";
export var two = "Discover-module--two--e08a8";
export var two2 = "Discover-module--two2--a47b8";