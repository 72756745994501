// extracted by mini-css-extract-plugin
export var bannerBtn = "HireCross-module--bannerBtn--212b5";
export var bannerBtn2 = "HireCross-module--bannerBtn2--f5e3f";
export var btn_white1_border = "HireCross-module--btn_white1_border--f9ee0";
export var cards = "HireCross-module--cards--ad70a";
export var description = "HireCross-module--description--d7e24";
export var hire = "HireCross-module--hire--33765";
export var hireCyber = "HireCross-module--hireCyber--0b0b2";
export var hireImg = "HireCross-module--hireImg--2ae2c";
export var hr = "HireCross-module--hr--e4167";
export var subTitle = "HireCross-module--subTitle--a8335";
export var talent = "HireCross-module--talent--aa517";
export var text = "HireCross-module--text--e84a7";
export var tick = "HireCross-module--tick--d9655";