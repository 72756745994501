// extracted by mini-css-extract-plugin
export var Group = "WhatMakes-module--Group--1d1da";
export var SliderWidth = "WhatMakes-module--SliderWidth--ff86e";
export var arrowImg = "WhatMakes-module--arrowImg--fd0b2";
export var card = "WhatMakes-module--card--a1c85";
export var cardWrapper = "WhatMakes-module--cardWrapper--ba027";
export var cards = "WhatMakes-module--cards--0adbb";
export var cir = "WhatMakes-module--cir--e82e9";
export var description = "WhatMakes-module--description--3c029";
export var dots1 = "WhatMakes-module--dots1--0061c";
export var heading = "WhatMakes-module--heading--e3542";
export var headingPremium = "WhatMakes-module--headingPremium--93796";
export var premiumBg = "WhatMakes-module--premiumBg--e13e4";
export var premiumImg = "WhatMakes-module--premiumImg--7a128";
export var providingImg = "WhatMakes-module--providingImg--27db4";
export var teamButton = "WhatMakes-module--teamButton--60c22";
export var teamWork = "WhatMakes-module--teamWork--3fb7f";
export var trailBg = "WhatMakes-module--trailBg--1987a";