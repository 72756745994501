// extracted by mini-css-extract-plugin
export var crossImg = "CrossPlatform-module--crossImg--8a56a";
export var description = "CrossPlatform-module--description--b6604";
export var faqButton = "CrossPlatform-module--faqButton--47bcd";
export var faqsNum = "CrossPlatform-module--faqsNum--e63db";
export var faqsSub = "CrossPlatform-module--faqsSub--5b3f6";
export var head = "CrossPlatform-module--head--732f2";
export var headr = "CrossPlatform-module--headr--6f49e";
export var quesColor = "CrossPlatform-module--quesColor--50bb2";
export var quesColorSelected = "CrossPlatform-module--quesColorSelected--25a87";
export var reverse = "CrossPlatform-module--reverse--14d16";
export var section = "CrossPlatform-module--section--3cb42";
export var serial = "CrossPlatform-module--serial--32656";
export var serial2 = "CrossPlatform-module--serial2--1ad23";
export var textColor = "CrossPlatform-module--textColor--43001";