// extracted by mini-css-extract-plugin
export var bannerBtn = "StayAhead-module--bannerBtn--b949b";
export var bgImg = "StayAhead-module--bgImg--95f47";
export var btn_white2_border = "StayAhead-module--btn_white2_border--f576a";
export var build = "StayAhead-module--build--f2902";
export var buildInner = "StayAhead-module--buildInner--9d762";
export var card = "StayAhead-module--card--d3a42";
export var cardData = "StayAhead-module--cardData--0d2ad";
export var center = "StayAhead-module--center--c5997";
export var description = "StayAhead-module--description--4e9bb";
export var h2 = "StayAhead-module--h2--88596";
export var heading = "StayAhead-module--heading--7831b";
export var pad = "StayAhead-module--pad--2cf7a";
export var ready = "StayAhead-module--ready--ef1ac";
export var readyBg = "StayAhead-module--readyBg--e049c";