import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import EngagementModels from "../../components/common/Engagement-Model/EngagementModels"
import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../../components/common/SEO_Revamp"
import Banner from "../../components/cross-platform/Banner"
import CreatePowerful from "../../components/cross-platform/CreatePowerful"
import CrossPlatform from "../../components/cross-platform/CrossPlatform"
import Discover from "../../components/cross-platform/Discover"
import Experience from "../../components/cross-platform/Experience"
import HireCross from "../../components/cross-platform/HireCross"
import StayAhead from "../../components/cross-platform/StayAhead"
import Superchange from "../../components/cross-platform/Superchange"
import Testimonials from "../../components/cross-platform/Testimonials"
import WhatMakes from "../../components/cross-platform/WhatMakes"
import WorkPortfolio from "../../components/common/WorkPortfolio"
import Testimonials2 from "../../components/healthcare/Testimonials2"
import MainLayout from "../../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/cross_platform_app_development_services_1d75c90486.webp"
    />
  )
}
const crossplatform = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const create = data?.strapiPage?.sections[1]
  const discover = data?.strapiPage?.sections[2]
  const platform = data?.strapiPage?.sections[3]
  const experience = data?.strapiPage?.sections[4]
  const unlock = data?.strapiPage?.sections[5]
  const stay = [data?.strapiPage?.sections[6], data?.strapiPage?.sections[7]]
  const models = data?.strapiPage?.sections[8]
  const cross = data?.strapiPage?.sections[9]
  const ready = data?.strapiPage?.sections[10]
  const testimonials = data?.strapiPage?.sections[12]
  const faqs = data?.strapiPage?.sections[13]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <CreatePowerful strapiData={create} />
      <Discover strapiData={discover} />
      <CrossPlatform strapiData={platform} />
      <Experience
        strapiData={experience}
        pageName="Cross Platform App Development"
      />
      <WhatMakes strapiData={unlock} />
      <StayAhead strapiData={stay} />
      {/* <Ready strapiData={ready2}/> */}
      <HireCross strapiData={cross} />
      <EngagementModels strapiData={models} />
      <Superchange strapiData={ready} />
      <WorkPortfolio
        portfolioHead={"See Our Work in Action"}
        portfolioDesc={
          "Immerse yourself in the extraordinary impact of our work through captivating showcases and real-life success stories."
        }
      />
      {!isMobile && !isTablet ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}
      <Faqs strapiData={faqs} pageName="Cross Platform App Development" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "cross-app" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default crossplatform
