// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--e86c5";
export var clientDots2 = "Testimonials-module--clientDots2--d56a8";
export var content = "Testimonials-module--content--56cc8";
export var dots3 = "Testimonials-module--dots3--d2e29";
export var hireArrow = "Testimonials-module--hireArrow--facc7";
export var iconContainer = "Testimonials-module--iconContainer--af0ef";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--61ada";
export var imgBtn = "Testimonials-module--imgBtn--a31a1";
export var person = "Testimonials-module--person--b1947";
export var personBg = "Testimonials-module--personBg--2c956";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--e943a";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--805d8";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--1b030";
export var row = "Testimonials-module--row--7871a";
export var section = "Testimonials-module--section--693ce";
export var staffRec = "Testimonials-module--staffRec--2cbff";
export var testName = "Testimonials-module--testName--32c38";
export var testimonials = "Testimonials-module--testimonials--7454d";
export var testimonialsContent = "Testimonials-module--testimonialsContent--931dc";
export var vector2 = "Testimonials-module--vector2--0eb7a";