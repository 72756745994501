import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dots from "../../images/softwere-development/Group1.png"
import dots2 from "../../images/softwere-development/Group4.png"
import Group2 from "../../images/softwere-development/Group2.png"
import Vector3 from "../../images/softwere-development/Vector3.png"
import cir from "../../images/custom-web-application/cir.svg"
import reactangel from "../../images/softwere-development/Vector.svg"
import * as styles from "./CreatePowerful.module.scss"

const CreatePowerful = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <div className={styles.cir}>
        <img decoding="async" loading="lazy" src={cir} alt="circle" />
      </div>
      <div className={styles.dots1}>
        <img decoding="async" loading="lazy" src={dots2} alt="dots" />
      </div>
      <div className={styles.Group}>
        <img decoding="async" loading="lazy" src={Group2} alt="dots" />
      </div>
      <div className={styles.react}>
        <img
          src={reactangel}
          decoding="async"
          loading="lazy"
          alt="reactangel"
        />
      </div>

      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className={styles.Vector}>
          <img decoding="async" loading="lazy" src={Vector3} alt="vector" />
        </div>
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={4} md={6} xs={12} key={i} className="gap-30">
              <div className={styles.card}>
                <img
                  src={e?.image1[0]?.localFile?.publicURL}
                  decoding="async"
                  loading="lazy"
                  alt={e?.title}
                />
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <div className={styles.dots4}>
        <img decoding="async" loading="lazy" src={dots} alt="dots" />
      </div>
      <div className={styles.dotscard}>
        <img decoding="async" loading="lazy" src={dots} alt="dots" />
      </div>
    </div>
  )
}

export default CreatePowerful
