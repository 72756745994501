import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import * as styles from "./StayAhead.module.scss"

const StayAhead = ({ strapiData }) => {
  return (
    <div className={styles.build}>
      <div>
        <Container className={styles.pad}>
          <div className={styles.buildInner}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData[0]?.title }}
            ></h2>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData[0]?.subTitle,
              }}
            />
            <div className={styles.cardData}>
              {strapiData[0]?.cards?.map((e, i) => (
                <div className={styles.card} key={i}>
                  <div className={styles.bgImg}>
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      decoding="async"
                      loading="lazy"
                      alt={e?.title}
                    />
                  </div>
                  <h3>{e?.title}</h3>
                  <p>{e?.subTitle}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.readyBg}>
            <Col lg={7}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData[1]?.title }} />
              <div className={`${styles.bannerBtn}`}>
                {strapiData[1]?.buttons[0] && (
                  <Link
                    to={strapiData[1]?.buttons[0]?.url}
                    className={styles.btn_white2_border}
                  >
                    {strapiData[1]?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </Col>
            <Col lg={4}></Col>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default StayAhead
