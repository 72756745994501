import React, { useRef, useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import left from "../../images/softwere-development/left.png"
import ratingStars from "../../images/softwere-development/rating-stars.png"
import right from "../../images/softwere-development/Right.png"
import stafff from "../../images/android-App-Development/hireCir.svg"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import Vector2 from "../../images/softwere-development/Vector2.png"
import hireArrow from "../../images/android-App-Development/hireArrow.svg"
import clientDots from "../../images/android-App-Development/clientDots.svg"
import clientDots2 from "../../images/android-App-Development/clientDots2.svg"
import * as styles from "./Testimonials.module.scss"
import "../../components/common/sliderNewDotsSoft.scss"

const Testimonials = ({ strapiData }) => {
  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.imgBtn} onClick={onClick}>
        <div className={styles.personBg}>
          <img src={left} decoding="async" loading="lazy" alt="arrow" />
        </div>
        <button>
          <img
            src={props?.img}
            decoding="async"
            loading="lazy"
            alt="background"
          />
        </button>
      </div>
    )
  }

  function NextArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.imgBtn} onClick={onClick}>
        <div className={styles.personBg}>
          <img src={right} decoding="async" loading="lazy" alt="arrow" />
        </div>
        <button>
          <img
            src={props?.img}
            decoding="async"
            loading="lazy"
            alt="background"
          />
        </button>
      </div>
    )
  }

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)

  const settings = {
    dots: true,
    dotsClass: "new-dots-soft",
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  return (
    <section className={styles.section}>
      <img
        decoding="async"
        loading="lazy"
        className={styles.clientDots2}
        src={clientDots2}
        alt="staff"
      />
      <div className={styles.dots3}>
        <img decoding="async" loading="lazy" src={Frame} alt="dots" />
      </div>
      <div className={styles.vector2}>
        <img decoding="async" loading="lazy" src={Vector2} alt="frame" />
      </div>

      <Container>
        <div className={styles.testimonialsContent}>
          {/* <p>Testimonials</p> */}
          <div>
            <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            <div
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </div>
          <div className="pt-2 pt-md-0">
            <a
              href="https://clutch.co/profile/invozone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="btn_black_border mt-4">
                Trusted by Top Companies
              </Button>
            </a>
          </div>
        </div>
        <div className={styles.staffRec}>
          <img decoding="async" loading="lazy" src={stafff} alt="staff" />
        </div>
        <div className={styles.hireArrow}>
          <img decoding="async" loading="lazy" src={hireArrow} alt="staff" />
        </div>
        <Row>
          <Col lg={2} className="user-select-none">
            <div className={styles.personBg}></div>
            <PrevArrow
              onClick={slide1?.current?.slickPrev}
              img={
                current === 0
                  ? strapiData?.cards[strapiData?.cards?.length - 1]?.image1[0]
                      ?.localFile?.publicURL
                  : strapiData?.cards[current - 1]?.image1[0]?.localFile
                      ?.publicURL
              }
            />
          </Col>
          <Col lg={8}>
            <Slider {...settings} ref={slide1}>
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => (
                  <div key={i}>
                    <Row className={`${styles.row} m-0`}>
                      <Col md={4} className="pl-0">
                        <img
                          className={styles.person}
                          src={e?.image1[0]?.localFile?.publicURL}
                          decoding="async"
                          loading="lazy"
                          alt={e?.title}
                        />
                      </Col>
                      <Col md={8} className={styles.content}>
                        <div className="mb-3">
                          <img
                            src={ratingStars}
                            alt="rating stars"
                            decoding="async"
                            loading="lazy"
                          />
                        </div>
                        <div
                          className="font-weight-normal mb-5"
                          dangerouslySetInnerHTML={{
                            __html: e?.description?.description,
                          }}
                        ></div>
                        <p className={styles.testName}>{e?.title}</p>
                      </Col>
                    </Row>
                  </div>
                ))}
            </Slider>
          </Col>
          <Col lg={2} className="user-select-none">
            <div className={styles.personBg}></div>
            <NextArrow
              onClick={slide1?.current?.slickNext}
              img={
                current === strapiData?.cards?.length - 1
                  ? strapiData?.cards[0]?.image1[0]?.localFile?.publicURL
                  : strapiData?.cards[current + 1]?.image1[0]?.localFile
                      ?.publicURL
              }
            />
          </Col>
        </Row>
      </Container>
      <img
        decoding="async"
        loading="lazy"
        className={styles.clientDots}
        src={clientDots}
        alt="staff"
      />
    </section>
  )
}

export default Testimonials
